import React from 'react';
import ASMLogoSvg from 'resources/svg/asm-logo.svg';
// import { Text } from 'components/typography';
import s from './s.module.scss';

const Logo: React.FC = () => (
  <div className={s.logo}>
    <img src={ASMLogoSvg} alt="ASM" sizes="(max-width 500px) 500px, 1000px" />
    {/* <Text className={s.text} type={'h1'} weight={'bold'}>
      Otto
    </Text> */}
  </div>
);

export default Logo;
