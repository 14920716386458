import React, { useState } from 'react';
import cn from 'classnames';
import styles from './s.module.scss';
import { Link, NavLink } from 'react-router-dom';
import TopNav from '../top-nav';
import { SiteNavigation } from 'layout/navigations';
import Dropdown from './dropdown';
import Logo from './logo';

interface Props {
  headerWide?: boolean | false;
}

const Header: React.FC<Props> = ({ headerWide }) => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn('container', styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Logo />
        </Link>
        <div className={styles.wrapper}>
          <div className={cn(styles.wrap, { [styles.visible]: visibleNav })}>
            {/* <nav className={styles.nav}>
              {SiteNavigation.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={styles.item}
                    activeClassName={styles.active}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                )
              )}
            </nav> */}
            {/* <NavLink
              className={cn('button-stroke', styles.button)}
              activeClassName={styles.active}
              to="/wallet-overview"
              onClick={() => setVisibleNav(false)}
            >
              Wallet
            </NavLink> */}
            <div className={styles.btns}>
              <NavLink
                className={cn('button-small', styles.button)}
                activeClassName={styles.active}
                to="/sign-up"
              >
                Sign Up
              </NavLink>
              <NavLink
                className={cn('button-stroke button-small', styles.button)}
                activeClassName={styles.active}
                to="/sign-in"
              >
                Login
              </NavLink>
            </div>
          </div>
          <div className={styles.control}>
            <TopNav />
          </div>
        </div>
        {/* <div className={styles.btns}>
            <Link
              className={cn("button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-up"
              onClick={() => setVisibleNav(false)}
            >
              Sign Up
            </Link>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-in"
              onClick={() => setVisibleNav(false)}
            >
              Login
            </Link>
          </div> */}
        <button
          className={cn(styles.burger, { [styles.active]: visibleNav })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Header;
