import { useState } from 'react';
import cn from 'classnames';
import styles from './s.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import Breadcrumbs from '../breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BreadcrumbsItem, Navigation } from 'types/navigation';

interface Props {
  navigationItems: Navigation[];
  title: string;
  breadcrumbs: BreadcrumbsItem[];
  children: React.ReactNode;
}

const ProfileContainer: React.FC<Props> = ({
  navigationItems,
  title,
  breadcrumbs,
  children,
}) => {
  const { pathname } = useLocation();
  const [visible, setVisible] = useState(false);

  const activeLink = navigationItems.find((x) => pathname.includes(x.url));

  return (
    <div className={styles.profile}>
      <div className={styles.head}>
        <div className={cn('container', styles.container)}>
          <h3 className={cn('h3', styles.title)}>{title}</h3>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
      </div>

      <div className={styles.body}>
        <div className={cn('container', styles.container)}>
          <div className={styles.sidebar}>
            <div className={cn(styles.dropdown, { [styles.active]: visible })}>
              <div className={styles.top} onClick={() => setVisible(!visible)}>
                {activeLink ? (
                  <FontAwesomeIcon icon={activeLink?.icon} size="2x" />
                ) : null}
                {activeLink?.title}
              </div>
              <div className={styles.group}>
                <div className={styles.menu}>
                  {navigationItems.map((item, index) => (
                    <NavLink
                      className={cn(styles.link, {
                        [styles.separator]: item.separator,
                      })}
                      activeClassName={styles.active}
                      key={index}
                      to={item.url}
                      exact
                      onClick={() => setVisible(false)}
                    >
                      <FontAwesomeIcon icon={item?.icon} size="2x" />
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.wrapper}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default ProfileContainer;
