import React, { useEffect } from 'react';
import { withRouter, useLocation, RouteComponentProps } from 'react-router-dom';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import styles from './s.module.scss';
import Header from '../header';
import Footer from '../footer';

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

const Page: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <>
      <div className={styles.page}>
        <Header />
        <div className={styles.inner}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(Page);
