import 'styles/app.scss';
import 'styles/antd/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { BrowserRouter as Router } from 'react-router-dom';
import Web3 from 'web3';
import { Web3ReactProvider } from '@web3-react/core';

function getLibrary(provider, connector) {
  return new Web3(provider);
}

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <Web3ReactProvider getLibrary={getLibrary}>
        <App />
      </Web3ReactProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
