import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import ErrorBoundary from './components/error-boundary';
import LayoutView from './layout';
import { Spin } from 'antd';

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <ErrorBoundary>
      <LayoutView />
    </ErrorBoundary>
  );
};

export default App;
