import React from 'react';
import s from './s.module.scss';
import { Text } from 'components/typography';

const Footer = () => (
  <footer className={s.footer}>
    <div className={s.footerBottom}>
      <Text type="small" className={s.copyright}>
        © Copyright Altered State Machine Ltd 2021, All Rights Reserved
      </Text>
    </div>
  </footer>
);

export default Footer;
