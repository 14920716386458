// navigation configurations for feature use.

import { faUser, faCheckCircle, faWallet } from '@fortawesome/free-solid-svg-icons';
import { Navigation } from 'types/navigation';

export const getUserProfileNavigation = (key: string): Navigation | undefined =>
  UserProfileNavigations.find((x) => x.key === key);

export const UserProfileNavigations: Navigation[] = [
  {
    key: 'profile',
    title: 'Profile',
    icon: faUser,
    url: '/user/profile',
    separator: false,
  },
  {
    key: 'verification',
    title: 'Verification',
    icon: faCheckCircle,
    url: '/user/verification/overview',
    separator: false,
  },
  // {
  //   key: 'wallet',
  //   title: 'My Wallet',
  //   icon: faWallet,
  //   url: '/user/wallet',
  //   separator: false,
  // },
];

export const UserProfileBreadcrumbs = {
  profile: [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Profile',
      url: '/user/profile',
    },
  ],
  verification: [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Verification',
      url: '/user/verification',
    },
  ],
  // wallet: [
  //   {
  //     title: 'Home',
  //     url: '/',
  //   },
  //   {
  //     title: 'Wallet',
  //     url: '/user/wallet',
  //   },
  // ],
};

export const SiteNavigation = [
  {
    title: 'Exchange',
    url: '/exchange',
  },
  {
    title: 'Buy Crypto',
    dropdown: [
      {
        title: 'Credit card',
        icon: 'user',
        url: '/buy-crypto',
      },
      {
        title: 'Bank deposit',
        icon: 'image',
        url: '/deposit-fiat',
      },
    ],
  },
  {
    title: 'Market',
    url: '/market',
  },
  {
    title: 'Discover',
    url: '/learn-crypto',
  },
];
