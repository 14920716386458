import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Divider, Dropdown, Menu, Image } from 'antd';
import { Link, NavLink } from 'react-router-dom';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <Link to="/login">
        <Button type="link" size="large">
          Log In
        </Button>
      </Link>
      <Divider type="vertical" />
      <Button
        type="link"
        size="large"
        onClick={() =>
          loginWithRedirect({
            screen_hint: 'signup',
            redirectUri: `${window.location.origin}/email-verification`,
          })
        }
      >
        Sign Up
      </Button>
    </>
  );
};

const UserNav = () => {
  const { logout, user } = useAuth0();
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <NavLink
          to="/user/profile"
          exact
          className="nav-link"
          activeClassName="router-link-exact-active"
        >
          Profile
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink
          to="/user/verification"
          exact
          className="nav-link"
          activeClassName="router-link-exact-active"
        >
          Verification
        </NavLink>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <NavLink
          to="/user/wallet"
          exact
          className="nav-link"
          activeClassName="router-link-exact-active"
        >
          Wallet
        </NavLink>
      </Menu.Item> */}
      <Menu.Divider />
      <Menu.Item
        key="3"
        onClick={() =>
          logout({
            returnTo: window.location.origin,
          })
        }
      >
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown overlay={menu} className="mr-32" placement="bottomCenter">
      {user && (
        <Avatar
          src={
            <Image src={user.picture} style={{ width: 32 }} preview={false} />
          }
        />
      )}
    </Dropdown>
  );
};

const TopNav = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? <UserNav /> : <Login />;
};

export default TopNav;
