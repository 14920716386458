import React, { lazy } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ProfileContainer from '../../components/profile-container';
import ProtectedRoute from '../../auth/protected-route';
import {
  getUserProfileNavigation,
  UserProfileBreadcrumbs,
  UserProfileNavigations,
} from 'layout/navigations';

const VerificationView = lazy(() => import('../../modules/verification'));
const ProfileView = lazy(() => import('../../modules/profile'));
const WalletView = lazy(() => import('../../modules/wallet'));

type Params = {
  module: string;
};

const UserProfile: React.FC = () => {
  const match = useRouteMatch();
  const { module } = match.params as Params;

  return (
    <ProfileContainer
      navigationItems={UserProfileNavigations}
      title={getUserProfileNavigation(module)?.title || ''}
      breadcrumbs={UserProfileBreadcrumbs[module]}
    >
      <ProtectedRoute path="/user/profile" component={ProfileView} />
      <ProtectedRoute path="/user/verification" component={VerificationView} />
      <ProtectedRoute path="/user/wallet" component={WalletView} />
    </ProfileContainer>
  );
};

export default UserProfile;
