const config = {
  env: String(process.env.REACT_APP_ENV),
  isDev: String(process.env.REACT_APP_ENV) === 'development',
  isProd: String(process.env.REACT_APP_ENV) === 'production',
  auth0: {
    domain: String(process.env.REACT_APP_AUTH0_DOMAIN),
    clientId: String(process.env.REACT_APP_AUTH0_CLIENT_ID),
    audience: String(process.env.REACT_APP_AUTH0_AUDIENCE),
  },
  serverUrl: String(process.env.REACT_APP_SERVER_URL),
  name: String(process.env.REACT_APP_NAME),
};

export const POLKADOT_EXTENSION_LINK = 'https://polkadot.js.org/extension/';
export const METAMASK_EXTENSION_LINK = 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn';
export default config;
