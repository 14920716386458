import { Auth0Provider } from '@auth0/auth0-react';
import config from 'config';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();
  const domain = config.auth0.domain;
  const clientId = config.auth0.clientId;
  const audience = config.auth0.audience;

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
