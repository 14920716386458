import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import AntdSpin from 'antd/lib/spin';
import s from './s.module.scss';
import ErrorBoundary from '../components/error-boundary';
import Page from './page';
import UserProfile from './user-profile';
import ProtectedRoute from 'auth/protected-route';

const HomeView = lazy(() => import('../modules/home'));
const LoginView = lazy(() => import('../modules/login'));
const EmailVerificationView = lazy(
  () => import('../modules/email-verification')
);

const LayoutView: React.FC = () => {
  return (
    <ErrorBoundary>
      <Page>
        <main className={s.main}>
          <Suspense fallback={<AntdSpin className="pv-24 ph-64" />}>
            <Switch>
              <Route path="/" exact component={HomeView} />
              <ProtectedRoute path="/user/:module" component={UserProfile} />
              <Route path="/login" exact component={LoginView} />
              <Route
                path="/email-verification"
                exact
                component={EmailVerificationView}
              />
            </Switch>
          </Suspense>
        </main>
      </Page>
    </ErrorBoundary>
  );
};

export default LayoutView;
